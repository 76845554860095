import Warp from 'warpjs'

export const initDistortionAnimation = (svg, radio = 50) => {
  const warp = new Warp(svg)

  warp.interpolate(6)
  warp.transform(([x, y]) => [x, y, x, y])

  const distance = (ax, ay, bx, by) => Math.sqrt((ax - bx) ** 2 + (ay - by) ** 2)

  let mouseX = 0,
    mouseY = 0,
    lastMouseX = 0,
    lastMouseY = 0

  svg.addEventListener('mousemove', (e) => requestAnimationFrame(() => {
    const viewbox = svg.viewBox.baseVal
    const correction = viewbox.width / svg.getBoundingClientRect().width
    lastMouseX = mouseX
    lastMouseY = mouseY
    mouseX = e.offsetX * correction
    mouseY = e.offsetY * correction
  }))

  // dispose the animation
  let dispose = false

  function distortt() {
    if (!lastMouseX && !lastMouseY || !mouseX || !mouseY) {
      // not initialized
      requestAnimationFrame(distortt)
    } else {
      const velocityX = mouseX - lastMouseX
      const velocityY = mouseY - lastMouseY
      warp.transform(([x, y, ox, oy]) => {
        let distorted = false
        // distort part
        if (velocityX && velocityY) {
          // movement detected
          lastMouseX = mouseX
          lastMouseY = mouseY
          const deltaX = x - mouseX
          const deltaY = y - mouseY
          const cuadraticMouseDistance = deltaX ** 2 + deltaY ** 2
          const cuadraticOriginDistance = (ox - x - velocityX) ** 2 + (oy - y - velocityY) ** 2
          if (cuadraticMouseDistance + cuadraticOriginDistance < radio ** 2) {
            // distort!
            distorted = true
            return [x + velocityX, y + velocityY, ox, oy]
          }
        }
        // recovery part
        if (!distorted) {
          const dist = distance(ox, oy, x, y)
          if (dist < 2) {
            return [ox, oy, ox, oy]
          } 
          // apprach actual point to original point
          // reduce distance in 0.75 factor
          return [(3 * x + ox) / 4, (3 * y + oy) / 4, ox, oy]
                    
        }

        // default part
        return [x, y, ox, oy]
      })

      if (!dispose)
        requestAnimationFrame(distortt)
    }
  }

  distortt()

  // return dispose function
  return () => {
    dispose = true 
  }
}