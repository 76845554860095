<template>
  <div ref="container">
  <svg v-if="$i18n.locale === 'en'" class="distortion-svg" width="1109.76" height="910.481" version="1.1" viewBox="0 0 293.624 240.898" xmlns="http://www.w3.org/2000/svg">
     <text transform="scale(.26458)" fill="#000000" font-family="sans-serif" font-size="40px" style="line-height:1.25;shape-inside:url(#rect5557);white-space:pre" xml:space="preserve"/>
 <g stroke-width=".26458" aria-label="At Trends Lab  we continuously  listen to the  heartbeat of the  society to detect  future trends.">
  <g stroke-width=".070004" aria-label="We detect  what people  do, instead  of what  peoples say.">
   <path d="m46.508 1.8891-10.795 31.485h-0.89958l-10.57-30.226-10.57 30.226h-0.89958l-10.795-31.485h0.98954l10.3 30.046 10.525-30.046h0.89958l10.57 30.136 10.3-30.136h0.92832z"/>
   <path d="m70.033 21.725h-20.69q0 3.1485 1.3944 5.6674 1.3944 2.4739 3.8232 3.8682 2.4739 1.3944 5.5324 1.3944 2.3839 0 4.4529-0.89958 2.114-0.94456 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7782 2.8337-2.249 0.98954-4.7678 0.98954-3.3285 0-5.9822-1.4843-2.6088-1.5293-4.1381-4.228-1.4843-2.6988-1.4843-6.0722t1.3944-6.0272q1.3944-2.6988 3.8232-4.1831 2.4739-1.5293 5.5324-1.5293 3.0136 0 5.4875 1.4843t3.8682 4.0931q1.4393 2.6088 1.4393 5.9372zm-10.795-10.885q-2.6988 0-4.9027 1.3044t-3.5534 3.5983q-1.3044 2.2939-1.4393 5.1276h19.836q-0.13494-2.8337-1.4843-5.1276t-3.5534-3.5983q-2.204-1.3044-4.9027-1.3044z"/>
   <path d="m108.72-1.297e-7v33.375h-0.89958v-7.1067q-1.1695 3.3285-3.9582 5.3075-2.7887 1.9341-6.432 1.9341-3.1485 0-5.7573-1.4843-2.5638-1.5293-4.0481-4.228t-1.4843-6.0722 1.4843-6.0272q1.4843-2.6988 4.0481-4.1831 2.6088-1.5293 5.7573-1.5293 3.5983 0 6.387 1.9791 2.8337 1.9341 4.0032 5.2176v-17.182zm-11.29 32.655q2.9236 0 5.3075-1.3944t3.7333-3.8682q1.3494-2.5188 1.3494-5.6674t-1.3494-5.6224q-1.3494-2.5188-3.7333-3.8682-2.3839-1.3944-5.3075-1.3944t-5.3075 1.3944q-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224t1.3494 5.6674q1.3494 2.4739 3.7333 3.8682 2.3839 1.3944 5.3075 1.3944z"/>
   <path d="m137.86 21.725h-20.69q0 3.1485 1.3944 5.6674 1.3944 2.4739 3.8232 3.8682 2.4739 1.3944 5.5324 1.3944 2.3839 0 4.4529-0.89958 2.114-0.94456 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7782 2.8337-2.249 0.98954-4.7678 0.98954-3.3285 0-5.9822-1.4843-2.6088-1.5293-4.1381-4.228-1.4843-2.6988-1.4843-6.0722t1.3944-6.0272q1.3944-2.6988 3.8232-4.1831 2.4738-1.5293 5.5324-1.5293 3.0136 0 5.4875 1.4843t3.8682 4.0931q1.4393 2.6088 1.4393 5.9372zm-10.795-10.885q-2.6988 0-4.9027 1.3044-2.204 1.3044-3.5534 3.5983-1.3044 2.2939-1.4393 5.1276h19.836q-0.13494-2.8337-1.4843-5.1276-1.3494-2.2939-3.5534-3.5983t-4.9027-1.3044z"/>
   <path d="m155.99 31.845q-0.76464 0.80963-2.0241 1.2594-1.2144 0.40481-2.5188 0.40481-2.6988 0-4.1831-1.5293-1.4393-1.5743-1.4393-4.1831v-16.822h-4.4979v-0.8546h4.4979v-5.0826h0.89959v5.0826h7.8714v0.8546h-7.8714v16.597q0 2.4739 1.2144 3.7782 1.2144 1.3044 3.5534 1.3044t3.8682-1.4393z"/>
   <path d="m180.82 21.725h-20.69q0 3.1485 1.3944 5.6674 1.3944 2.4739 3.8232 3.8682 2.4739 1.3944 5.5324 1.3944 2.3839 0 4.4529-0.89958 2.114-0.94456 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7782 2.8337-2.249 0.98954-4.7678 0.98954-3.3285 0-5.9822-1.4843-2.6088-1.5293-4.1381-4.228-1.4843-2.6988-1.4843-6.0722t1.3944-6.0272q1.3944-2.6988 3.8232-4.1831 2.4738-1.5293 5.5324-1.5293 3.0136 0 5.4875 1.4843t3.8682 4.0931q1.4393 2.6088 1.4393 5.9372zm-10.795-10.885q-2.6988 0-4.9027 1.3044-2.204 1.3044-3.5534 3.5983-1.3044 2.2939-1.4393 5.1276h19.836q-0.13494-2.8337-1.4843-5.1276-1.3494-2.2939-3.5534-3.5983t-4.9027-1.3044z"/>
   <path d="m197.1 33.51q-3.2835 0-5.8923-1.4843-2.6088-1.5293-4.0931-4.1831-1.4393-2.6988-1.4393-6.1172t1.4393-6.0722q1.4843-2.6988 4.0931-4.1831t5.8923-1.4843q2.5638 0 4.7228 0.98954 2.204 0.94456 3.6433 2.7887l-0.62971 0.67469q-1.3044-1.7992-3.3285-2.6988-2.0241-0.89958-4.408-0.89958-3.0136 0-5.4425 1.3944-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224 0 3.1935 1.3494 5.6674t3.7333 3.8682q2.4289 1.3944 5.4425 1.3944 2.3839 0 4.408-0.89958t3.3285-2.6988l0.62971 0.67469q-1.4393 1.8442-3.6433 2.8337-2.159 0.94456-4.7228 0.94456z"/>
   <path d="m223.68 31.845q-0.76464 0.80963-2.0241 1.2594-1.2144 0.40481-2.5188 0.40481-2.6988 0-4.1831-1.5293-1.4393-1.5743-1.4393-4.1831v-16.822h-4.4979v-0.8546h4.4979v-5.0826h0.89959v5.0826h7.8714v0.8546h-7.8714v16.597q0 2.4739 1.2144 3.7782t3.5534 1.3044q2.3389 0 3.8682-1.4393z"/>
   <path d="m42.056 58.785-8.7709 24.199h-6.7469l-5.4425-15.023-5.6224 15.023h-6.7469l-8.726-24.199h6.6119l5.7124 16.372 5.9373-16.372h5.9372l5.7573 16.372 5.8923-16.372h6.1904z"/>
   <path d="m60.047 58.425q4.4979 0 7.2416 2.6988 2.7887 2.6988 2.7887 8.0063v13.854h-7.0168v-12.774q0-2.8787-1.2594-4.273-1.2594-1.4393-3.6433-1.4393-2.6538 0-4.228 1.6642-1.5743 1.6192-1.5743 4.8578v11.964h-7.0168v-33.375h7.0168v11.695q1.3944-1.3944 3.3734-2.114 1.9791-0.76465 4.318-0.76465z"/>
   <path d="m86.18 58.425q5.6224 0 8.636 2.6988 3.0136 2.6538 3.0136 8.0513v13.809h-6.567v-3.0136q-1.9791 3.3734-7.3766 3.3734-2.7887 0-4.8578-0.94456-2.0241-0.94456-3.1036-2.6088-1.0795-1.6642-1.0795-3.7782 0-3.3734 2.5188-5.3075 2.5638-1.9341 7.8714-1.9341h5.5774q0-2.2939-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8232 0.62971-1.8442 0.58473-3.1485 1.6192l-2.5188-4.9027q1.9791-1.3944 4.7228-2.159 2.7887-0.76465 5.7124-0.76465zm-0.53975 20.196q1.7992 0 3.1935-0.80963 1.3944-0.8546 1.9791-2.4739v-2.4739h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76465 2.9236 0.76465z"/>
   <path d="m119.78 81.814q-1.0345 0.76465-2.5638 1.1695-1.4843 0.35983-3.1486 0.35983-4.318 0-6.7019-2.204-2.3389-2.204-2.3389-6.477v-9.9404h-3.7333v-5.3975h3.7333v-5.8923h7.0168v5.8923h6.0272v5.3975h-6.0272v9.8504q0 1.5293 0.76464 2.3839 0.80963 0.80963 2.249 0.80963 1.6642 0 2.8337-0.89958z"/>
   <path d="m150.77 58.425q3.3734 0 6.1172 1.5743 2.7887 1.5293 4.363 4.363 1.5743 2.7887 1.5743 6.522t-1.5743 6.567q-1.5743 2.7887-4.363 4.363-2.7437 1.5293-6.1172 1.5293-4.6329 0-7.2866-2.9236v11.29h-7.0168v-32.925h6.7019v2.7887q2.6088-3.1485 7.6015-3.1485zm-1.2144 19.161q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027t-1.7542-4.8578q-1.7092-1.8442-4.408-1.8442t-4.4529 1.8442q-1.7092 1.7992-1.7092 4.8578t1.7092 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m191.21 70.974q0 0.13494-0.13494 1.8891h-18.306q0.49477 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58473 2.7437-1.7992l3.7333 4.0481q-3.4184 3.9132-9.9854 3.9132-4.0931 0-7.2417-1.5743-3.1485-1.6192-4.8578-4.4529t-1.7092-6.432q0-3.5534 1.6642-6.387 1.7092-2.8787 4.6329-4.4529 2.9686-1.6192 6.6119-1.6192 3.5534 0 6.432 1.5293t4.4979 4.408q1.6642 2.8337 1.6642 6.6119zm-12.549-7.2416q-2.3839 0-4.0032 1.3494t-1.9791 3.6883h11.92q-0.35983-2.2939-1.9791-3.6433-1.6192-1.3944-3.9582-1.3944z"/>
   <path d="m207.4 83.344q-3.8232 0-6.8818-1.5743-3.0136-1.6192-4.7228-4.4529t-1.7092-6.432 1.7092-6.432 4.7228-4.408q3.0586-1.6192 6.8818-1.6192t6.8368 1.6192q3.0136 1.5743 4.7228 4.408t1.7092 6.432-1.7092 6.432-4.7228 4.4529q-3.0136 1.5743-6.8368 1.5743zm0-5.7573q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027t-1.7542-4.8578q-1.7092-1.8442-4.408-1.8442t-4.4529 1.8442q-1.7542 1.7992-1.7542 4.8578t1.7542 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m239.65 58.425q3.3734 0 6.1172 1.5743 2.7887 1.5293 4.363 4.363 1.5743 2.7887 1.5743 6.522t-1.5743 6.567q-1.5743 2.7887-4.363 4.363-2.7437 1.5293-6.1172 1.5293-4.6328 0-7.2866-2.9236v11.29h-7.0168v-32.925h6.7019v2.7887q2.6088-3.1485 7.6015-3.1485zm-1.2144 19.161q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027t-1.7542-4.8578q-1.7092-1.8442-4.408-1.8442t-4.4529 1.8442q-1.7092 1.7992-1.7092 4.8578t1.7092 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m256.38 49.609h7.0168v33.375h-7.0168z"/>
   <path d="m293.62 70.974q0 0.13494-0.13494 1.8891h-18.306q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58473 2.7437-1.7992l3.7333 4.0481q-3.4184 3.9132-9.9854 3.9132-4.0931 0-7.2416-1.5743-3.1485-1.6192-4.8578-4.4529t-1.7092-6.432q0-3.5534 1.6642-6.387 1.7092-2.8787 4.6329-4.4529 2.9686-1.6192 6.6119-1.6192 3.5534 0 6.432 1.5293 2.8787 1.5293 4.4979 4.408 1.6642 2.8337 1.6642 6.6119zm-12.549-7.2416q-2.3839 0-4.0032 1.3494t-1.9791 3.6883h11.92q-0.35983-2.2939-1.9791-3.6433-1.6192-1.3944-3.9582-1.3944z"/>
   <path d="m27.842 99.219v33.375h-6.7019v-2.7887q-2.6088 3.1486-7.5565 3.1486-3.4184 0-6.2071-1.5293-2.7437-1.5293-4.318-4.363t-1.5743-6.567 1.5743-6.567 4.318-4.363q2.7887-1.5293 6.2071-1.5293 4.6329 0 7.2416 2.9236v-11.74zm-13.044 27.977q2.6538 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027 0-3.0586-1.7542-4.8578-1.7542-1.8441-4.408-1.8441-2.6988 0-4.4529 1.8441-1.7542 1.7992-1.7542 4.8578t1.7542 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m45.834 132.95q-3.8232 0-6.8818-1.5743-3.0136-1.6192-4.7228-4.4529t-1.7092-6.432 1.7092-6.432 4.7228-4.408q3.0586-1.6192 6.8818-1.6192t6.8368 1.6192q3.0136 1.5743 4.7228 4.408t1.7092 6.432-1.7092 6.432-4.7228 4.4529q-3.0136 1.5743-6.8368 1.5743zm0-5.7573q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027 0-3.0586-1.7542-4.8578-1.7092-1.8441-4.408-1.8441t-4.4529 1.8441q-1.7542 1.7992-1.7542 4.8578t1.7542 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m66.164 124.23q1.8891 0 3.1036 1.2144 1.2144 1.1695 1.2144 3.1036 0 0.89959-0.2249 1.7992-0.2249 0.89959-0.98954 2.6988l-2.5638 6.477h-4.4529l1.9791-7.1517q-1.1245-0.4498-1.7992-1.4393-0.62971-1.0345-0.62971-2.3839 0-1.9341 1.2144-3.1036 1.2594-1.2144 3.1485-1.2144z"/>
   <path d="m88.429 109.34h0.89958v23.254h-0.89958zm0.44979-6.432q-0.49477 0-0.8546-0.35983-0.31485-0.35984-0.31485-0.80963 0-0.49477 0.31485-0.80962 0.35983-0.35984 0.8546-0.35984 0.44979 0 0.80963 0.35984 0.35983 0.31485 0.35983 0.80962 0 0.44979-0.35983 0.80963-0.35983 0.35983-0.80963 0.35983z"/>
   <path d="m110.33 109.2q4.363 0 6.8368 2.5188 2.4738 2.4739 2.4738 7.1517v13.719h-0.89958v-13.719q0-4.273-2.204-6.522-2.159-2.2939-6.1622-2.2939-4.5429 0-7.1967 2.7437-2.6088 2.6988-2.6088 7.1517v12.639h-0.89958v-23.254h0.89958v6.2071q1.0795-2.9686 3.5983-4.6329 2.5188-1.7092 6.1622-1.7092z"/>
   <path d="m135.07 132.73q-2.7887 0-5.2626-0.89958-2.4739-0.89959-3.8232-2.2939l0.53975-0.71967q1.3494 1.3044 3.5983 2.204 2.2939 0.85461 4.9477 0.85461 3.9582 0 5.8473-1.3494 1.8891-1.3944 1.8891-3.8232 0-1.7542-1.0345-2.7437-0.98955-1.0345-2.4739-1.4843-1.4393-0.49477-4.0032-0.98954-2.7437-0.49477-4.4529-1.0345-1.6642-0.58473-2.8337-1.7992-1.1245-1.2594-1.1245-3.4634 0-2.5188 2.069-4.228 2.114-1.7542 6.1622-1.7542 2.159 0 4.273 0.67469 2.114 0.62971 3.4184 1.7092l-0.53975 0.71966q-1.3494-1.0795-3.2385-1.6642-1.8891-0.58473-3.9132-0.58473-3.6433 0-5.4875 1.4393-1.7992 1.3944-1.7992 3.6883 0 1.8442 1.0345 2.9236 1.0345 1.0345 2.5638 1.5293t4.0481 0.94457q2.7437 0.53975 4.363 1.0795t2.7437 1.7542q1.1245 1.1695 1.1245 3.2835 0 2.7437-2.204 4.408-2.204 1.6192-6.432 1.6192z"/>
   <path d="m161.07 131.06q-0.76464 0.80963-2.0241 1.2594-1.2144 0.40481-2.5188 0.40481-2.6988 0-4.1831-1.5293-1.4393-1.5743-1.4393-4.1831v-16.822h-4.4979v-0.8546h4.4979v-5.0826h0.89959v5.0826h7.8714v0.8546h-7.8714v16.597q0 2.4738 1.2144 3.7782t3.5534 1.3044q2.3389 0 3.8682-1.4393z"/>
   <path d="m185.9 120.94h-20.69q0 3.1485 1.3944 5.6674 1.3944 2.4739 3.8232 3.8682 2.4739 1.3944 5.5324 1.3944 2.3839 0 4.453-0.89959 2.114-0.94456 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7782 2.8337t-4.7678 0.98954q-3.3285 0-5.9822-1.4843-2.6088-1.5293-4.1381-4.228-1.4843-2.6988-1.4843-6.0722t1.3944-6.0272q1.3944-2.6988 3.8232-4.1831 2.4738-1.5293 5.5324-1.5293 3.0136 0 5.4875 1.4843t3.8682 4.0931q1.4393 2.6088 1.4393 5.9372zm-10.795-10.885q-2.6988 0-4.9027 1.3044t-3.5534 3.5983q-1.3044 2.2939-1.4393 5.1276h19.836q-0.13493-2.8337-1.4843-5.1276t-3.5534-3.5983-4.9027-1.3044z"/>
   <path d="m200.25 109.2q4.0481 0 6.1622 2.114 2.159 2.069 2.159 6.0272v15.248h-0.89958v-4.9477q-1.1245 2.3839-3.4184 3.7333-2.249 1.3494-5.4425 1.3494-3.8682 0-6.0722-1.7542-2.159-1.7992-2.159-4.7678 0-2.7887 1.9341-4.4979 1.9791-1.7542 6.3421-1.7542h8.8159v-2.5638q0-3.6433-1.8891-5.4875-1.8441-1.8442-5.4875-1.8442-2.5188 0-4.7228 0.89959-2.159 0.8546-3.7333 2.3389l-0.62971-0.62971q1.6642-1.6192 4.0481-2.5188 2.3839-0.94456 4.9927-0.94456zm-1.4393 22.67q6.477 0 8.8609-5.9373v-5.1276h-8.8609q-3.7782 0-5.5774 1.4393-1.7542 1.4393-1.7542 3.9132 0 2.6538 1.9341 4.1831t5.3975 1.5293z"/>
   <path d="m238.48 99.219v33.375h-0.89959v-7.1067q-1.1695 3.3285-3.9582 5.3075-2.7887 1.9341-6.432 1.9341-3.1486 0-5.7573-1.4843-2.5638-1.5293-4.0481-4.228t-1.4843-6.0722 1.4843-6.0272q1.4843-2.6988 4.0481-4.1831 2.6088-1.5293 5.7573-1.5293 3.5983 0 6.387 1.9791 2.8337 1.9341 4.0032 5.2176v-17.182zm-11.29 32.655q2.9236 0 5.3075-1.3944t3.7333-3.8682q1.3494-2.5188 1.3494-5.6674t-1.3494-5.6224q-1.3494-2.5188-3.7333-3.8682-2.3839-1.3944-5.3075-1.3944t-5.3076 1.3944q-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224t1.3494 5.6674q1.3494 2.4739 3.7333 3.8682 2.3839 1.3944 5.3076 1.3944z"/>
   <path d="m13.674 182.34q-3.1935 0-5.8023-1.4843-2.5638-1.5293-4.0481-4.228-1.4393-2.6988-1.4393-6.0722t1.4393-6.0272q1.4843-2.6988 4.0481-4.1831 2.6088-1.5293 5.8023-1.5293t5.7573 1.5293q2.6088 1.4843 4.0481 4.1831 1.4843 2.6538 1.4843 6.0272t-1.4843 6.0722q-1.4393 2.6988-4.0481 4.228-2.5638 1.4843-5.7573 1.4843zm0-0.8546q2.9236 0 5.3076-1.3944 2.3839-1.3944 3.7333-3.8682 1.3494-2.5188 1.3494-5.6674 0-3.1485-1.3494-5.6224-1.3494-2.5188-3.7333-3.8682-2.3839-1.3944-5.3076-1.3944t-5.3076 1.3944q-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224 0 3.1486 1.3494 5.6674 1.3494 2.4739 3.7333 3.8682 2.3839 1.3944 5.3076 1.3944z"/>
   <path d="m38.592 149.55q-2.3389 0-3.5534 1.3044-1.2144 1.3044-1.2144 3.7782v4.318h7.8714v0.85461h-7.8714v22.4h-0.89958v-22.4h-4.4979v-0.85461h4.4979v-4.5429q0-2.6088 1.4843-4.1381 1.4843-1.5743 4.1381-1.5743 1.3044 0 2.5188 0.4498 1.2594 0.40481 2.0241 1.2144l-0.62971 0.62971q-1.5293-1.4393-3.8682-1.4393z"/>
   <path d="m94.726 158-8.7709 24.199h-6.7469l-5.4425-15.023-5.6224 15.023h-6.7469l-8.726-24.199h6.6119l5.7124 16.372 5.9372-16.372h5.9372l5.7573 16.372 5.8923-16.372h6.1916z"/>
   <path d="m112.72 157.64q4.4979 0 7.2416 2.6988 2.7887 2.6988 2.7887 8.0063v13.854h-7.0168v-12.774q0-2.8787-1.2594-4.273-1.2594-1.4393-3.6433-1.4393-2.6538 0-4.228 1.6642-1.5743 1.6192-1.5743 4.8578v11.964h-7.0168v-33.375h7.0168v11.695q1.3944-1.3944 3.3734-2.114 1.9791-0.76465 4.318-0.76465z"/>
   <path d="m138.85 157.64q5.6224 0 8.636 2.6988 3.0136 2.6538 3.0136 8.0513v13.809h-6.567v-3.0136q-1.9791 3.3734-7.3766 3.3734-2.7887 0-4.8578-0.94456-2.0241-0.94457-3.1036-2.6088t-1.0795-3.7782q0-3.3734 2.5188-5.3075 2.5638-1.9341 7.8714-1.9341h5.5774q0-2.2939-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8232 0.62971-1.8442 0.58473-3.1485 1.6192l-2.5188-4.9027q1.9791-1.3944 4.7228-2.159 2.7887-0.76465 5.7124-0.76465zm-0.53975 20.196q1.7992 0 3.1935-0.80962 1.3944-0.85461 1.9791-2.4739v-2.4738h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76464 2.9236 0.76464z"/>
   <path d="m172.45 181.03q-1.0345 0.76464-2.5638 1.1695-1.4843 0.35983-3.1485 0.35983-4.318 0-6.7019-2.204-2.3389-2.204-2.3389-6.477v-9.9404h-3.7333v-5.3975h3.7333v-5.8923h7.0168v5.8923h6.0272v5.3975h-6.0272v9.8504q0 1.5293 0.76465 2.3839 0.80963 0.80963 2.249 0.80963 1.6642 0 2.8337-0.89958z"/>
   <path d="m17.497 207.25q3.3734 0 6.1172 1.5743 2.7887 1.5293 4.363 4.363 1.5743 2.7887 1.5743 6.522t-1.5743 6.567q-1.5743 2.7887-4.363 4.363-2.7437 1.5293-6.1172 1.5293-4.6329 0-7.2866-2.9236v11.29h-7.0168v-32.925h6.7019v2.7887q2.6088-3.1485 7.6015-3.1485zm-1.2144 19.161q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027 0-3.0586-1.7542-4.8578-1.7092-1.8441-4.408-1.8441t-4.4529 1.8441q-1.7092 1.7992-1.7092 4.8578t1.7092 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m57.933 219.8q0 0.13494-0.13494 1.8891h-18.306q0.49477 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58473 2.7437-1.7992l3.7333 4.0481q-3.4184 3.9132-9.9854 3.9132-4.0931 0-7.2416-1.5743-3.1485-1.6192-4.8578-4.4529t-1.7092-6.432q0-3.5534 1.6642-6.387 1.7092-2.8787 4.6329-4.4529 2.9686-1.6192 6.6119-1.6192 3.5534 0 6.432 1.5293t4.4979 4.408q1.6642 2.8337 1.6642 6.6119zm-12.549-7.2416q-2.3839 0-4.0032 1.3494t-1.9791 3.6883h11.92q-0.35983-2.2939-1.9791-3.6433-1.6192-1.3944-3.9582-1.3944z"/>
   <path d="m74.126 232.17q-3.8232 0-6.8818-1.5743-3.0136-1.6192-4.7228-4.4529t-1.7092-6.432 1.7092-6.432 4.7228-4.408q3.0586-1.6192 6.8818-1.6192t6.8368 1.6192q3.0136 1.5743 4.7228 4.408t1.7092 6.432-1.7092 6.432-4.7228 4.4529q-3.0136 1.5743-6.8368 1.5743zm0-5.7573q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027 0-3.0586-1.7542-4.8578-1.7092-1.8441-4.408-1.8441t-4.4529 1.8441q-1.7542 1.7992-1.7542 4.8578t1.7542 4.9027q1.7542 1.7992 4.4529 1.7992z"/>
   <path d="m106.38 207.25q3.3734 0 6.1172 1.5743 2.7887 1.5293 4.363 4.363 1.5743 2.7887 1.5743 6.522t-1.5743 6.567q-1.5743 2.7887-4.363 4.363-2.7437 1.5293-6.1172 1.5293-4.6328 0-7.2866-2.9236v11.29h-7.0168v-32.925h6.7019v2.7887q2.6088-3.1485 7.6015-3.1485zm-1.2144 19.161q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9027 0-3.0586-1.7542-4.8578-1.7092-1.8441-4.408-1.8441t-4.453 1.8441q-1.7092 1.7992-1.7092 4.8578t1.7092 4.9027q1.7542 1.7992 4.453 1.7992z"/>
   <path d="m123.11 198.44h7.0168v33.375h-7.0168z"/>
   <path d="m160.35 219.8q0 0.13494-0.13494 1.8891h-18.306q0.49477 2.249 2.3389 3.5534 1.8441 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58473 2.7437-1.7992l3.7333 4.0481q-3.4184 3.9132-9.9854 3.9132-4.0931 0-7.2416-1.5743-3.1486-1.6192-4.8578-4.4529t-1.7092-6.432q0-3.5534 1.6642-6.387 1.7092-2.8787 4.6329-4.4529 2.9686-1.6192 6.6119-1.6192 3.5534 0 6.432 1.5293t4.4979 4.408q1.6642 2.8337 1.6642 6.6119zm-12.549-7.2416q-2.3839 0-4.0032 1.3494t-1.9791 3.6883h11.92q-0.35984-2.2939-1.9791-3.6433-1.6192-1.3944-3.9582-1.3944z"/>
   <path d="m186.5 232.17q-3.0136 0-5.8923-0.71966-2.8787-0.76465-4.5879-1.8891l2.3389-5.0377q1.6192 1.0345 3.9132 1.7092 2.2939 0.62971 4.4979 0.62971 4.4529 0 4.4529-2.204 0-1.0345-1.2144-1.4843-1.2144-0.44979-3.7333-0.76464-2.9686-0.44979-4.9027-1.0345-1.9341-0.58473-3.3734-2.069-1.3944-1.4843-1.3944-4.228 0-2.2939 1.3044-4.0481 1.3494-1.7992 3.8682-2.7887 2.5638-0.98954 6.0272-0.98954 2.5638 0 5.0826 0.58473 2.5638 0.53975 4.228 1.5293l-2.3389 4.9927q-3.1935-1.7992-6.9718-1.7992-2.249 0-3.3734 0.62971t-1.1245 1.6192q0 1.1245 1.2144 1.5743 1.2144 0.44979 3.8682 0.8546 2.9686 0.49477 4.8578 1.0795 1.8891 0.53975 3.2835 2.0241 1.3944 1.4843 1.3944 4.1381 0 2.249-1.3494 4.0032t-3.9582 2.7437q-2.5638 0.94456-6.1172 0.94456z"/>
   <path d="m212.01 207.25q5.6224 0 8.636 2.6988 3.0136 2.6538 3.0136 8.0513v13.809h-6.567v-3.0136q-1.9791 3.3734-7.3766 3.3734-2.7887 0-4.8578-0.94456-2.0241-0.94456-3.1036-2.6088t-1.0795-3.7783q0-3.3734 2.5188-5.3075 2.5638-1.9341 7.8714-1.9341h5.5774q0-2.2939-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8232 0.62971-1.8441 0.58473-3.1485 1.6192l-2.5188-4.9027q1.9791-1.3944 4.7228-2.159 2.7887-0.76464 5.7124-0.76464zm-0.53975 20.196q1.7992 0 3.1935-0.80963 1.3944-0.8546 1.9791-2.4738v-2.4739h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76465 2.9236 0.76465z"/>
   <path d="m253.57 207.61-10.93 25.683c-1.1095 2.7887-2.4888 4.7528-4.1381 5.8923-1.6192 1.1395-3.5833 1.7092-5.8923 1.7092-1.2594 0-2.5038-0.19491-3.7333-0.58473s-2.234-0.92957-3.0136-1.6192l2.5638-4.9927c0.53975 0.47977 1.1545 0.8546 1.8442 1.1245 0.71967 0.26987 1.4243 0.40481 2.114 0.40481 0.95956 0 1.7392-0.23989 2.3389-0.71967 0.59973-0.44979 1.1395-1.2144 1.6192-2.2939l0.09-0.22489-10.48-24.379h7.2416l6.7919 16.417 6.8368-16.417h6.7384z"/>
  </g>
 </g>
 <g transform="translate(-64.531 24.424)" stroke-width=".26458" aria-label=".">
  <path d="m322.1 207.39q-1.8442 0-3.1036-1.2594t-1.2594-3.1485q0-1.9341 1.2594-3.1036 1.2594-1.2144 3.1036-1.2144t3.1036 1.2144q1.2594 1.1695 1.2594 3.1036 0 1.8891-1.2594 3.1485t-3.1036 1.2594z"/>
 </g>
  </svg>

  <svg v-if="$i18n.locale === 'es'" class="distortion-svg" width="1327.1" height="882.36" version="1.1" viewBox="0 0 351.12 233.46" xmlns="http://www.w3.org/2000/svg">
    <text transform="scale(.26458)" fill="#000000" font-family="sans-serif" font-size="40px" style="line-height:1.25;shape-inside:url(#rect5557);white-space:pre" xml:space="preserve"/>
 <g stroke-width=".26459" aria-label="Detectamos lo que la gente hace, y no lo que la gente dice.">
  <path d="m6.0319 1.5875h11.92q4.9028 0 8.6361 2.0241 3.7783 2.0241 5.8473 5.6224 2.114 3.5984 2.114 8.0963t-2.114 8.0963q-2.0691 3.5984-5.8473 5.6224-3.7333 2.0241-8.6361 2.0241h-11.92zm11.83 30.631q4.7229 0 8.2762-1.8891 3.5984-1.9341 5.5325-5.3076 1.9791-3.4184 1.9791-7.6915t-1.9791-7.6465q-1.9341-3.4184-5.5325-5.3076-3.5534-1.9341-8.2762-1.9341h-10.93v29.776z"/>
  <path d="m61.177 21.424h-20.691q0 3.1486 1.3944 5.6674 1.3944 2.4739 3.8233 3.8682 2.4739 1.3944 5.5325 1.3944 2.3839 0 4.453-0.89959 2.114-0.94457 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7783 2.8337t-4.7678 0.98955q-3.3285 0-5.9823-1.4843-2.6088-1.5293-4.1381-4.2281-1.4843-2.6988-1.4843-6.0722t1.3944-6.0273q1.3944-2.6988 3.8233-4.1831 2.4739-1.5293 5.5325-1.5293 3.0136 0 5.4875 1.4843 2.4739 1.4843 3.8682 4.0931 1.4393 2.6088 1.4393 5.9373zm-10.795-10.885q-2.6988 0-4.9028 1.3044-2.204 1.3044-3.5534 3.5984-1.3044 2.294-1.4393 5.1277h19.836q-0.13494-2.8337-1.4843-5.1277-1.3494-2.294-3.5534-3.5984-2.204-1.3044-4.9028-1.3044z"/>
  <path d="m79.304 31.544q-0.76465 0.80963-2.0241 1.2594-1.2144 0.40482-2.5189 0.40482-2.6988 0-4.1831-1.5293-1.4393-1.5743-1.4393-4.1831v-16.822h-4.498v-0.85461h4.498v-5.0827h0.89959v5.0827h7.8714v0.85461h-7.8714v16.597q0 2.4739 1.2144 3.7783 1.2144 1.3044 3.5534 1.3044 2.3389 0 3.8682-1.4393z"/>
  <path d="m104.13 21.424h-20.691q0 3.1486 1.3944 5.6674 1.3944 2.4739 3.8233 3.8682 2.4739 1.3944 5.5325 1.3944 2.3839 0 4.453-0.89959 2.114-0.94457 3.4634-2.6988l0.62971 0.62971q-1.5293 1.8442-3.7783 2.8337-2.249 0.98955-4.7678 0.98955-3.3285 0-5.9823-1.4843-2.6088-1.5293-4.1381-4.2281-1.4843-2.6988-1.4843-6.0722t1.3944-6.0273q1.3944-2.6988 3.8233-4.1831 2.4739-1.5293 5.5325-1.5293 3.0136 0 5.4875 1.4843 2.4739 1.4843 3.8682 4.0931 1.4394 2.6088 1.4394 5.9373zm-10.795-10.885q-2.6988 0-4.9028 1.3044-2.204 1.3044-3.5534 3.5984-1.3044 2.294-1.4393 5.1277h19.836q-0.13494-2.8337-1.4843-5.1277-1.3494-2.294-3.5534-3.5984-2.204-1.3044-4.9028-1.3044z"/>
  <path d="m120.41 33.208q-3.2835 0-5.8923-1.4843-2.6088-1.5293-4.0932-4.1831-1.4394-2.6988-1.4394-6.1172 0-3.4184 1.4394-6.0722 1.4843-2.6988 4.0932-4.1831 2.6088-1.4843 5.8923-1.4843 2.5638 0 4.7229 0.98955 2.204 0.94457 3.6434 2.7887l-0.62972 0.67469q-1.3044-1.7992-3.3285-2.6988-2.0241-0.89959-4.408-0.89959-3.0136 0-5.4425 1.3944-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224 0 3.1936 1.3494 5.6674 1.3494 2.4739 3.7333 3.8682 2.4289 1.3944 5.4425 1.3944 2.3839 0 4.408-0.89959 2.0241-0.89959 3.3285-2.6988l0.62972 0.67469q-1.4394 1.8442-3.6434 2.8337-2.159 0.94457-4.7229 0.94457z"/>
  <path d="m147 31.544q-0.76465 0.80963-2.0241 1.2594-1.2144 0.40482-2.5189 0.40482-2.6988 0-4.1831-1.5293-1.4394-1.5743-1.4394-4.1831v-16.822h-4.498v-0.85461h4.498v-5.0827h0.89959v5.0827h7.8714v0.85461h-7.8714v16.597q0 2.4739 1.2144 3.7783 1.2144 1.3044 3.5534 1.3044t3.8682-1.4393z"/>
  <path d="m161.35 9.6838q4.0482 0 6.1622 2.114 2.159 2.0691 2.159 6.0273v15.248h-0.89959v-4.9478q-1.1245 2.3839-3.4184 3.7333-2.249 1.3494-5.4425 1.3494-3.8682 0-6.0722-1.7542-2.159-1.7992-2.159-4.7678 0-2.7887 1.9341-4.498 1.9791-1.7542 6.3421-1.7542h8.816v-2.5638q0-3.6433-1.8891-5.4875-1.8442-1.8442-5.4875-1.8442-2.5189 0-4.7229 0.89959-2.159 0.85461-3.7333 2.3389l-0.62971-0.62972q1.6642-1.6193 4.0482-2.5189 2.3839-0.94457 4.9927-0.94457zm-1.4394 22.67q6.4771 0 8.861-5.9373v-5.1277h-8.861q-3.7783 0-5.5775 1.4393-1.7542 1.4393-1.7542 3.9132 0 2.6538 1.9341 4.1831 1.9341 1.5293 5.3976 1.5293z"/>
  <path d="m208.66 9.6838q4.2281 0 6.612 2.5189 2.4289 2.4739 2.4289 7.1518v13.719h-0.89959v-13.719q0-4.2731-2.114-6.522-2.114-2.294-5.9823-2.294-4.408 0-6.9718 2.7438-2.5638 2.6988-2.5638 7.1518v12.639h-0.89959v-13.719q0-4.2731-2.114-6.522-2.114-2.294-5.9823-2.294-4.408 0-6.9718 2.7438-2.5638 2.6988-2.5638 7.1518v12.639h-0.89959v-23.254h0.89959v6.1622q1.0345-2.9687 3.5084-4.6329 2.4739-1.6642 5.9823-1.6642 3.5534 0 5.8474 1.7992 2.294 1.7542 2.9686 5.1277 0.89959-3.1936 3.4184-5.0377 2.5638-1.8891 6.2971-1.8891z"/>
  <path d="m236.33 33.208q-3.1936 0-5.8024-1.4843-2.5638-1.5293-4.0482-4.2281-1.4394-2.6988-1.4394-6.0722t1.4394-6.0273q1.4843-2.6988 4.0482-4.1831 2.6088-1.5293 5.8024-1.5293t5.7574 1.5293q2.6088 1.4843 4.0482 4.1831 1.4843 2.6538 1.4843 6.0273t-1.4843 6.0722q-1.4394 2.6988-4.0482 4.2281-2.5638 1.4843-5.7574 1.4843zm0-0.85461q2.9237 0 5.3076-1.3944 2.3839-1.3944 3.7333-3.8682 1.3494-2.5189 1.3494-5.6674t-1.3494-5.6224q-1.3494-2.5189-3.7333-3.8682-2.3839-1.3944-5.3076-1.3944-2.9237 0-5.3076 1.3944-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224t1.3494 5.6674q1.3494 2.4739 3.7333 3.8682 2.3839 1.3944 5.3076 1.3944z"/>
  <path d="m260.57 33.208q-2.7887 0-5.2626-0.89959-2.4739-0.89959-3.8233-2.294l0.53975-0.71967q1.3494 1.3044 3.5984 2.204 2.294 0.85461 4.9478 0.85461 3.9582 0 5.8473-1.3494 1.8892-1.3944 1.8892-3.8233 0-1.7542-1.0345-2.7438-0.98955-1.0345-2.4739-1.4843-1.4394-0.49478-4.0032-0.98955-2.7438-0.49478-4.453-1.0345-1.6642-0.58474-2.8337-1.7992-1.1245-1.2594-1.1245-3.4634 0-2.5189 2.0691-4.2281 2.114-1.7542 6.1622-1.7542 2.159 0 4.2731 0.67469 2.114 0.62971 3.4184 1.7092l-0.53976 0.71967q-1.3494-1.0795-3.2385-1.6642t-3.9132-0.58474q-3.6434 0-5.4875 1.4393-1.7992 1.3944-1.7992 3.6883 0 1.8442 1.0345 2.9237 1.0345 1.0345 2.5638 1.5293t4.0482 0.94457q2.7438 0.53976 4.363 1.0795 1.6193 0.53976 2.7438 1.7542 1.1245 1.1695 1.1245 3.2835 0 2.7438-2.204 4.408-2.204 1.6193-6.4321 1.6193z"/>
  <path d="m3.2881 49.308h7.0168v33.375h-7.0168z"/>
  <path d="m28.342 83.043q-3.8233 0-6.8819-1.5743-3.0136-1.6193-4.7229-4.453-1.7092-2.8337-1.7092-6.4321t1.7092-6.4321q1.7092-2.8337 4.7229-4.408 3.0586-1.6193 6.8819-1.6193t6.8369 1.6193q3.0136 1.5743 4.7229 4.408t1.7092 6.4321-1.7092 6.4321q-1.7092 2.8337-4.7229 4.453-3.0136 1.5743-6.8369 1.5743zm0-5.7574q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9028 0-3.0586-1.7542-4.8578-1.7092-1.8442-4.408-1.8442t-4.453 1.8442q-1.7542 1.7992-1.7542 4.8578 0 3.0586 1.7542 4.9028 1.7542 1.7992 4.453 1.7992z"/>
  <path d="m83.577 58.484v32.925h-7.0168v-11.29q-2.6088 2.9237-7.2417 2.9237-3.4184 0-6.2072-1.5293-2.7438-1.5293-4.318-4.363t-1.5743-6.567q0-3.7333 1.5743-6.567 1.5743-2.8337 4.318-4.363 2.7887-1.5293 6.2072-1.5293 4.9478 0 7.5566 3.1486v-2.7887zm-13.044 18.801q2.6538 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9028 0-3.0586-1.7542-4.8578-1.7542-1.8442-4.408-1.8442-2.6988 0-4.453 1.8442-1.7542 1.7992-1.7542 4.8578 0 3.0586 1.7542 4.9028 1.7542 1.7992 4.453 1.7992z"/>
  <path d="m114.43 58.484v24.199h-6.657v-2.8787q-1.3944 1.5743-3.3285 2.4289-1.9341 0.80963-4.1831 0.80963-4.7678 0-7.5566-2.7438-2.7887-2.7438-2.7887-8.1413v-13.674h7.0168v12.639q0 5.8473 4.9028 5.8473 2.5189 0 4.0482-1.6193 1.5293-1.6642 1.5293-4.9028v-11.965z"/>
  <path d="m144.66 70.673q0 0.13494-0.13494 1.8891h-18.307q0.49477 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8892 0 3.3285-0.53976 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0932 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9687-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494t-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m162.07 49.308h7.0168v33.375h-7.0168z"/>
  <path d="m185.37 58.124q5.6224 0 8.6361 2.6988 3.0136 2.6538 3.0136 8.0513v13.809h-6.567v-3.0136q-1.9791 3.3735-7.3767 3.3735-2.7887 0-4.8578-0.94457-2.0241-0.94457-3.1036-2.6088t-1.0795-3.7783q0-3.3735 2.5189-5.3076 2.5638-1.9341 7.8714-1.9341h5.5775q0-2.294-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8233 0.62971-1.8442 0.58474-3.1486 1.6193l-2.5189-4.9028q1.9791-1.3944 4.7229-2.159 2.7887-0.76465 5.7124-0.76465zm-0.53975 20.196q1.7992 0 3.1936-0.80963 1.3944-0.85461 1.9791-2.4739v-2.4739h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76465 2.9237 0.76465z"/>
  <path d="m241.05 58.484v20.151q0 6.657-3.4634 9.8955t-10.12 3.2385q-3.5084 0-6.657-0.85461-3.1486-0.85461-5.2176-2.4739l2.7887-5.0377q1.5293 1.2594 3.8682 1.9791 2.3389 0.76465 4.6779 0.76465 3.6434 0 5.3526-1.6642 1.7542-1.6193 1.7542-4.9478v-1.0345q-2.7438 3.0136-7.6465 3.0136-3.3285 0-6.1172-1.4393-2.7438-1.4843-4.363-4.1381-1.6193-2.6538-1.6193-6.1172 0-3.4634 1.6193-6.1172 1.6193-2.6538 4.363-4.0931 2.7887-1.4843 6.1172-1.4843 5.2626 0 8.0064 3.4634v-3.1036zm-13.269 17.272q2.7887 0 4.5429-1.6193 1.7992-1.6642 1.7992-4.318 0-2.6538-1.7992-4.2731-1.7542-1.6642-4.5429-1.6642-2.7887 0-4.5879 1.6642-1.7992 1.6193-1.7992 4.2731 0 2.6538 1.7992 4.318 1.7992 1.6193 4.5879 1.6193z"/>
  <path d="m271.28 70.673q0 0.13494-0.13494 1.8891h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53976 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9686-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35983-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m290.66 58.124q4.498 0 7.2417 2.6988 2.7887 2.6988 2.7887 8.0064v13.854h-7.0168v-12.774q0-2.8787-1.2594-4.2731-1.2594-1.4393-3.6434-1.4393-2.6538 0-4.2281 1.6642-1.5743 1.6193-1.5743 4.8578v11.965h-7.0168v-24.199h6.702v2.8337q1.3944-1.5293 3.4634-2.3389 2.0691-0.85461 4.5429-0.85461z"/>
  <path d="m322.64 81.514q-1.0345 0.76465-2.5638 1.1695-1.4843 0.35984-3.1486 0.35984-4.318 0-6.702-2.204-2.3389-2.204-2.3389-6.4771v-9.9405h-3.7333v-5.3976h3.7333v-5.8923h7.0168v5.8923h6.0273v5.3976h-6.0273v9.8505q0 1.5293 0.76465 2.3839 0.80963 0.80963 2.249 0.80963 1.6642 0 2.8337-0.89959z"/>
  <path d="m349.54 70.673q0 0.13494-0.13494 1.8891h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8892 0 3.3285-0.53976 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9686-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m17.996 107.73q4.498 0 7.2417 2.6988 2.7887 2.6988 2.7887 8.0064v13.854h-7.0168v-12.774q0-2.8787-1.2594-4.2731-1.2594-1.4394-3.6433-1.4394-2.6538 0-4.2281 1.6642-1.5743 1.6193-1.5743 4.8578v11.965h-7.0168v-33.375h7.0168v11.695q1.3944-1.3944 3.3735-2.114 1.9791-0.76465 4.318-0.76465z"/>
  <path d="m44.13 107.73q5.6224 0 8.6361 2.6988 3.0136 2.6538 3.0136 8.0514v13.809h-6.567v-3.0136q-1.9791 3.3735-7.3767 3.3735-2.7887 0-4.8578-0.94458-2.0241-0.94457-3.1036-2.6088-1.0795-1.6642-1.0795-3.7783 0-3.3735 2.5189-5.3076 2.5638-1.9341 7.8714-1.9341h5.5775q0-2.294-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8233 0.62971-1.8442 0.58474-3.1486 1.6193l-2.5189-4.9028q1.9791-1.3944 4.7229-2.159 2.7887-0.76465 5.7124-0.76465zm-0.53976 20.196q1.7992 0 3.1936-0.80963 1.3944-0.85462 1.9791-2.4739v-2.4739h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76466 2.9237 0.76466z"/>
  <path d="m74.086 132.65q-3.8682 0-6.9718-1.5743-3.0586-1.6193-4.8128-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5984 1.7092-6.4321 1.7542-2.8337 4.8128-4.408 3.1036-1.6193 6.9718-1.6193 3.8233 0 6.657 1.6193 2.8787 1.5743 4.1831 4.5429l-5.4425 2.9237q-1.8891-3.3285-5.4425-3.3285-2.7438 0-4.5429 1.7992t-1.7992 4.9028q0 3.1036 1.7992 4.9028 1.7992 1.7992 4.5429 1.7992 3.5984 0 5.4425-3.3285l5.4425 2.9686q-1.3044 2.8787-4.1831 4.498-2.8337 1.6193-6.657 1.6193z"/>
  <path d="m112.36 120.28q0 0.13493-0.13494 1.8891h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8892 0 3.3285-0.53976 1.4843-0.58473 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9687-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6434-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m119.74 123.93q1.8892 0 3.1036 1.2144 1.2144 1.1695 1.2144 3.1036 0 0.89959-0.2249 1.7992t-0.98955 2.6988l-2.5638 6.4771h-4.453l1.9791-7.1518q-1.1245-0.44979-1.7992-1.4394-0.62972-1.0345-0.62972-2.3839 0-1.9341 1.2144-3.1036 1.2594-1.2144 3.1486-1.2144z"/>
  <path d="m160.9 109.04-12.369 26.988q-1.2594 2.7887-2.8337 3.9582-1.5743 1.1695-3.7333 1.1695-3.1486 0-5.0377-2.0691l0.67469-0.71968q0.94458 1.0345 1.9791 1.4843 1.0345 0.4498 2.4289 0.4498 1.7992 0 3.1036-1.0345 1.3494-0.98955 2.5189-3.5084l1.6193-3.5534-10.66-23.164h1.0345l10.12 22.22 10.12-22.22z"/>
  <path d="m188.24 108.9q4.363 0 6.8369 2.5189 2.4739 2.4739 2.4739 7.1518v13.719h-0.8996v-13.719q0-4.2731-2.204-6.522-2.159-2.294-6.1622-2.294-4.5429 0-7.1967 2.7438-2.6088 2.6988-2.6088 7.1518v12.639h-0.8996v-23.254h0.8996v6.2072q1.0795-2.9686 3.5984-4.6329 2.5189-1.7092 6.1622-1.7092z"/>
  <path d="m216.18 132.43q-3.1936 0-5.8024-1.4843-2.5638-1.5293-4.0482-4.2281-1.4394-2.6988-1.4394-6.0722t1.4394-6.0273q1.4843-2.6988 4.0482-4.1831 2.6088-1.5293 5.8024-1.5293t5.7574 1.5293q2.6088 1.4843 4.0482 4.1831 1.4843 2.6538 1.4843 6.0273t-1.4843 6.0722q-1.4394 2.6988-4.0482 4.2281-2.5638 1.4843-5.7574 1.4843zm0-0.85461q2.9237 0 5.3076-1.3944t3.7333-3.8682q1.3494-2.5189 1.3494-5.6674t-1.3494-5.6224q-1.3494-2.5188-3.7333-3.8682-2.3839-1.3944-5.3076-1.3944-2.9237 0-5.3076 1.3944-2.3839 1.3494-3.7333 3.8682-1.3494 2.4739-1.3494 5.6224t1.3494 5.6674q1.3494 2.4739 3.7333 3.8682 2.3839 1.3944 5.3076 1.3944z"/>
  <path d="m3.2881 148.53h7.0168v33.375h-7.0168z"/>
  <path d="m28.342 182.26q-3.8233 0-6.8819-1.5743-3.0136-1.6193-4.7229-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5984 1.7092-6.4321 1.7092-2.8337 4.7229-4.408 3.0586-1.6193 6.8819-1.6193t6.8369 1.6193q3.0136 1.5743 4.7229 4.408 1.7092 2.8337 1.7092 6.4321 0 3.5984-1.7092 6.4321-1.7092 2.8337-4.7229 4.453-3.0136 1.5743-6.8369 1.5743zm0-5.7574q2.6988 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9028 0-3.0586-1.7542-4.8578-1.7092-1.8442-4.408-1.8442t-4.453 1.8442q-1.7542 1.7992-1.7542 4.8578 0 3.0586 1.7542 4.9028 1.7542 1.7992 4.453 1.7992z"/>
  <path d="m83.577 157.7v32.925h-7.0168v-11.29q-2.6088 2.9237-7.2417 2.9237-3.4184 0-6.2072-1.5293-2.7438-1.5293-4.318-4.363-1.5743-2.8337-1.5743-6.567 0-3.7333 1.5743-6.567 1.5743-2.8337 4.318-4.363 2.7887-1.5293 6.2072-1.5293 4.9478 0 7.5566 3.1486v-2.7887zm-13.044 18.801q2.6538 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9028 0-3.0586-1.7542-4.8578-1.7542-1.8442-4.408-1.8442-2.6988 0-4.453 1.8442-1.7542 1.7992-1.7542 4.8578 0 3.0586 1.7542 4.9028 1.7542 1.7992 4.453 1.7992z"/>
  <path d="m114.43 157.7v24.199h-6.657v-2.8787q-1.3944 1.5743-3.3285 2.4289-1.9341 0.80963-4.1831 0.80963-4.7678 0-7.5566-2.7438-2.7887-2.7438-2.7887-8.1413v-13.674h7.0168v12.639q0 5.8474 4.9028 5.8474 2.5189 0 4.0482-1.6193 1.5293-1.6642 1.5293-4.9028v-11.965z"/>
  <path d="m144.66 169.89q0 0.13494-0.13494 1.8892h-18.307q0.49477 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8892 0 3.3285-0.53975 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0932 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9687-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m162.07 148.53h7.0168v33.375h-7.0168z"/>
  <path d="m185.37 157.34q5.6224 0 8.6361 2.6988 3.0136 2.6538 3.0136 8.0514v13.809h-6.567v-3.0136q-1.9791 3.3735-7.3767 3.3735-2.7887 0-4.8578-0.94457-2.0241-0.94457-3.1036-2.6088-1.0795-1.6642-1.0795-3.7783 0-3.3735 2.5189-5.3076 2.5638-1.9341 7.8714-1.9341h5.5775q0-2.294-1.3944-3.5084-1.3944-1.2594-4.1831-1.2594-1.9341 0-3.8233 0.62971-1.8442 0.58473-3.1486 1.6193l-2.5189-4.9028q1.9791-1.3944 4.7229-2.159 2.7887-0.76465 5.7124-0.76465zm-0.53975 20.196q1.7992 0 3.1936-0.80964 1.3944-0.85461 1.9791-2.4739v-2.4739h-4.8128q-4.318 0-4.318 2.8337 0 1.3494 1.0345 2.159 1.0795 0.76466 2.9237 0.76466z"/>
  <path d="m241.05 157.7v20.151q0 6.657-3.4634 9.8955-3.4634 3.2385-10.12 3.2385-3.5084 0-6.657-0.85462-3.1486-0.85461-5.2176-2.4739l2.7887-5.0377q1.5293 1.2594 3.8682 1.9791 2.3389 0.76466 4.6779 0.76466 3.6434 0 5.3526-1.6642 1.7542-1.6193 1.7542-4.9478v-1.0345q-2.7438 3.0136-7.6465 3.0136-3.3285 0-6.1172-1.4394-2.7438-1.4843-4.363-4.1381-1.6193-2.6538-1.6193-6.1172t1.6193-6.1172q1.6193-2.6538 4.363-4.0932 2.7887-1.4843 6.1172-1.4843 5.2626 0 8.0064 3.4634v-3.1036zm-13.269 17.272q2.7887 0 4.5429-1.6193 1.7992-1.6642 1.7992-4.318t-1.7992-4.2731q-1.7542-1.6642-4.5429-1.6642-2.7887 0-4.5879 1.6642-1.7992 1.6193-1.7992 4.2731t1.7992 4.318q1.7992 1.6193 4.5879 1.6193z"/>
  <path d="m271.28 169.89q0 0.13494-0.13494 1.8892h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9686-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35983-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m290.66 157.34q4.498 0 7.2417 2.6988 2.7887 2.6988 2.7887 8.0064v13.854h-7.0168v-12.774q0-2.8787-1.2594-4.2731-1.2594-1.4394-3.6434-1.4394-2.6538 0-4.2281 1.6642-1.5743 1.6193-1.5743 4.8578v11.965h-7.0168v-24.199h6.702v2.8337q1.3944-1.5293 3.4634-2.3389 2.0691-0.85461 4.5429-0.85461z"/>
  <path d="m322.64 180.73q-1.0345 0.76465-2.5638 1.1695-1.4843 0.35983-3.1486 0.35983-4.318 0-6.702-2.204-2.3389-2.204-2.3389-6.4771v-9.9405h-3.7333v-5.3976h3.7333v-5.8923h7.0168v5.8923h6.0273v5.3976h-6.0273v9.8505q0 1.5293 0.76465 2.3839 0.80963 0.80963 2.249 0.80963 1.6642 0 2.8337-0.89959z"/>
  <path d="m349.54 169.89q0 0.13494-0.13494 1.8892h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8892 0 3.3285-0.53975 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9686-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293 2.8787 1.5293 4.498 4.408 1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6433-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m27.937 198.14v33.375h-6.702v-2.7887q-2.6088 3.1486-7.5566 3.1486-3.4184 0-6.2072-1.5293-2.7438-1.5293-4.318-4.363-1.5743-2.8337-1.5743-6.567t1.5743-6.567 4.318-4.363q2.7887-1.5293 6.2072-1.5293 4.6329 0 7.2417 2.9237v-11.74zm-13.044 27.977q2.6538 0 4.408-1.7992 1.7542-1.8442 1.7542-4.9028t-1.7542-4.8578q-1.7542-1.8442-4.408-1.8442-2.6988 0-4.453 1.8442-1.7542 1.7992-1.7542 4.8578t1.7542 4.9028q1.7542 1.7992 4.453 1.7992z"/>
  <path d="m34.414 207.31h7.0168v24.199h-7.0168zm3.5084-3.3735q-1.9341 0-3.1486-1.1245-1.2144-1.1245-1.2144-2.7887 0-1.6642 1.2144-2.7887 1.2144-1.1245 3.1486-1.1245 1.9341 0 3.1486 1.0795 1.2144 1.0795 1.2144 2.6988 0 1.7542-1.2144 2.9237-1.2144 1.1245-3.1486 1.1245z"/>
  <path d="m59.647 231.87q-3.8682 0-6.9718-1.5743-3.0586-1.6193-4.8128-4.453-1.7092-2.8337-1.7092-6.4321t1.7092-6.4321q1.7542-2.8337 4.8128-4.408 3.1036-1.6193 6.9718-1.6193 3.8233 0 6.657 1.6193 2.8787 1.5743 4.1831 4.5429l-5.4425 2.9237q-1.8891-3.3285-5.4425-3.3285-2.7438 0-4.5429 1.7992-1.7992 1.7992-1.7992 4.9028t1.7992 4.9028q1.7992 1.7992 4.5429 1.7992 3.5984 0 5.4425-3.3285l5.4425 2.9686q-1.3044 2.8787-4.1831 4.498-2.8337 1.6193-6.657 1.6193z"/>
  <path d="m97.925 219.5q0 0.13494-0.13494 1.8891h-18.307q0.49478 2.249 2.3389 3.5534 1.8442 1.3044 4.5879 1.3044 1.8891 0 3.3285-0.53975 1.4843-0.58474 2.7438-1.7992l3.7333 4.0482q-3.4184 3.9132-9.9855 3.9132-4.0931 0-7.2417-1.5743-3.1486-1.6193-4.8578-4.453-1.7092-2.8337-1.7092-6.4321 0-3.5534 1.6642-6.3871 1.7092-2.8787 4.6329-4.453 2.9687-1.6193 6.612-1.6193 3.5534 0 6.4321 1.5293t4.498 4.408q1.6642 2.8337 1.6642 6.612zm-12.549-7.2417q-2.3839 0-4.0032 1.3494-1.6193 1.3494-1.9791 3.6883h11.92q-0.35984-2.294-1.9791-3.6434-1.6193-1.3944-3.9582-1.3944z"/>
  <path d="m105.26 231.87q-1.8442 0-3.1036-1.2594-1.2594-1.2594-1.2594-3.1486 0-1.9341 1.2594-3.1036 1.2594-1.2144 3.1036-1.2144 1.8442 0 3.1036 1.2144 1.2594 1.1695 1.2594 3.1036 0 1.8891-1.2594 3.1486t-3.1036 1.2594z"/>
 </g>
    </svg>
  </div>
</template>

<script>
import {initDistortionAnimation} from '../assets/js/warp-animations'

export default {
  name: 'WeDetectDistortionAnimation',
  mounted() {
    this.disposeAnimation = initDistortionAnimation(this.$refs.container.querySelector('svg'), 10)
  },
  updated() {
    this.disposeAnimation && this.disposeAnimation()
    this.disposeAnimation = initDistortionAnimation(this.$refs.container.querySelector('svg'), 10)
  },
  unmounted() {
    this.disposeAnimation()
  }
}
</script>

<style scoped>
.distortion-svg {
  display: block;
  width: 100%;
  height: auto;
}
</style>