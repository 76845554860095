<template>
  <!-- background white -->
  <div class="bg-white">
    <!-- Fixed max-width wrapper -->
    <div class="delimiter">
      <!-- Title -->
      <section
        class="
        xl:pt-80 xl:pl-44
        lg:pt-56 lg:px-32
        md:pt-44 md:px-24
        pt-44 px-8"
      >
        <we-detect-distortion-animation></we-detect-distortion-animation>
      </section>
    </div>

    <!-- Services -->
    <section
      class="relative
      xl:pb-96 xl:pt-96
      lg:pb-64 
      md:pb-52
      pt-64 overflow-hidden"
    >
      <img
        class="img-casa enter-services enter-casa"
        src="@/assets/img/en-casa-ultima-semana.png"
        alt="En casa ultima semana"
      />

      <div class="delimiter">
        <h1
          class="uppercase font-display enter-services enter-fade-up show
          xl:pl-44
          lg:pl-32
          md:pl-24 md:text-9xl md:pt-0
          px-8 text-6xl pt-8"
        >
          {{ $t('services.services') }}
        </h1>
        <div
          class="flex relative
          xl:pl-44 xl:mt-52
          lg:pl-32 lg:mt-36
          md:pl-24 md:mt-36
          px-8 mt-24"
        >
          <div class="paragraph-digest enter-services enter-fade-up show">
            <h2
              class="font-bold
              md:text-6xl
              text-4xl"
            >
              {{ $t('services.digestReport') }}
            </h2>
            <p
              class="font-light mt-14
              lg:text-5xl lg:leading-snug
              md:text-4xl md:leading-snug
              text-3xl leading-snug"
              v-html="$t('services.provide')"
            ></p>
          </div>
        </div>
      </div>
    </section>

    <section
      class="overflow-hidden relative
      xl:pb-80 xl:mb-20
      lg:mb-20
      md:mb-20
      pb-52 pt-32 mb-20"
    >
      <img
        class="img-under enter-services enter-under"
        src="@/assets/img/under.png"
        alt="En casa ultima semana"
      />

      <div class="delimiter">
        <div
          class="relative flex justify-end
          xl:pr-44
          lg:pr-12
          md:pr-12
          px-8"
        >
          <div class="paragraph-forecasting flex flex-col">
            <div
              class="
              md:self-start md:w-full
              sm:self-end sm:w-80"
            >
              <h2
                class="font-light
                lg:text-7xl
                md:text-6xl
                text-5xl"
              >
                {{ $t('services.trendsForecasting') }}
              </h2>
              <p
                class="font-extrabold mt-16
                lg:text-6xl
                md:text-5xl
                text-3xl"
                v-html="$t('services.consulting')"
              ></p>
              <p
                class="font-medium italic mt-16 leading-tight
                lg:text-5xl
                md:text-4xl
                text-2xl"
                v-html="$t('services.formula')"
              ></p>
            </div>
            <p
              class="font-light mt-16
              lg:text-5xl lg:leading-snug
              md:text-4xl md:leading-snug
              text-3xl leading-snug"
              v-html="$t('services.theObjective')"
            ></p>
            <p
              class="font-light
              lg:text-5xl lg:leading-snug
              md:text-4xl md:mt-16 md:leading-snug
              text-3xl mt-8 leading-snug"
              v-html="$t('services.weWill')"
            ></p>
          </div>
        </div>
      </div>
    </section>

    <!-- Check out trends -->
    <div class="delimiter">
      <section
        class="overflow-hidden flex flex-wrap relative enter-services enter-fade-up show
        xl:pt-16 xl:pb-96 xl:px-42
        lg:pb-64 lg:px-32
        md:pb-64 md:px-24
        pt-14 pb-64 px-8 flex-col"
      >
        <div class="paragraph-trends">
          <h1
            class="font-bold
            lg:text-6xl
            md:text-5xl
            text-4xl"
            v-html="$t('services.checkOut')"
          ></h1>
          <router-link
            class="inline-block bg-gray-500 py-4 px-8 rounded-full text-white
            md:mt-12
            mt-8"
            to="/#trends"
            v-html="$t('services.download')"
          >
          </router-link>
        </div>
        <img
          class="img-typewriter self-end
          md:mt-0
          mt-8"
          src="@/assets/img/typewriter.png"
          alt="Typewriter"
        />
      </section>
    </div>
  </div>
</template>

<script>
import '../assets/css/enter-animations.css'
import initEnterAnimations from '../assets/js/enter-animations'
import WeDetectDistortionAnimation from '../components/WeDetectDistortionAnimation.vue'

export default {
  name: 'Services',
  components: { WeDetectDistortionAnimation },
  mounted() {
    this.disposeEnterAnimations = initEnterAnimations('.enter-services')
  },
  unmounted() {
    this.disposeEnterAnimations()
  },
}
</script>

<style scoped>
.img-casa {
  height: 23rem;
  object-fit: contain;
  transform: rotate(-51deg);
}
.img-under {
  position: relative;
  left: -34px;
  top: 0;
  height: 486px;
  object-fit: contain;
  transform: rotate(15deg);
}
.img-typewriter {
  position: static;
  transform: rotate(-45deg);
  height: 217px;
  object-fit: contain;
}
.text-xxl {
  font-size: 10.625rem;
  line-height: 1.1;
}
.enter-under {
  transform: translateX(-100%) rotate(15deg);
  transition: transform 0.6s ease;
  will-change: transform, opacity;
}
.enter-under.show {
  transform: translateX(0) rotate(15deg);
}
.enter-casa {
  transform: translateX(100%) rotate(-51deg);
  transition: transform 0.6s ease;
  will-change: transform, opacity;
}
.enter-casa.show {
  transform: translateX(100px) rotate(-51deg);
}

.paragraph-digest {
  width: 100%;
}
.paragraph-trends {
  width: 100%;
}
.paragraph-forecasting {
  width: 100%;
}
.px-42 {
  padding-left: 10.625rem;
  padding-right: 10.625rem;
}
@media (min-width: 480px) {
  .img-casa {
    position: absolute;
    right: -5px;
    top: 196px;
    height: 20rem;
  }
  .img-under {
    position: absolute;
    left: calc(-365px + 50vw);
    top: 118px;
    height: 386px;
  }
  .img-typewriter {
    height: 356px;
  }
}
@media (min-width: 768px) {
  .img-casa {
    right: -336px;
    top: 360px;
    height: 31rem;
  }
  .img-under {
    position: absolute;
    left: calc(-880px + 50vw);
    top: 34px;
    height: 1107px;
  }
  .img-typewriter {
    position: static;
    height: 383px;
  }
  .paragraph-digest {
    width: 538px;
  }
  .paragraph-forecasting {
    width: 539px;
  }
  .paragraph-trends {
    width: 100%;
  }
  .enter-casa.show {
    transform: translateX(0) rotate(-51deg);
  }
}
@media (min-width: 1024px) {
  .img-casa {
    right: -300px;
    top: 373px;
    height: 33rem;
  }
  .img-under {
    left: calc(-1146px + 50vw);
    top: 65px;
    height: 1178px;
  }
  .img-typewriter {
    position: static;
    height: 467px;
  }
  .paragraph-digest {
    width: 727px;
  }
  .paragraph-forecasting {
    width: 717px;
  }
  .paragraph-trends {
    width: 673px;
  }
}
@media (min-width: 1440px) {
  .img-casa {
    right: -300px;
    top: 474px;
    height: 40rem;
  }
  .img-under {
    left: -507px;
    top: 63px;
    height: 1344px;
  }
  .img-typewriter {
    position: absolute;
    right: 5rem;
    transform: rotate(-45deg);
    height: 467px;
  }
}
</style>
